import React from 'react'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import { Table, Divider } from 'antd'

const { Column } = Table

const formatNumber = x => numeral(x).format('0a')

const UsersTable = ({ users, loading }) => {
  return (
    <Table dataSource={users} loading={loading} rowKey="userName">
      <Column
        title="Username"
        dataIndex="userName"
        key="userName"
        render={text =>
          <a href={`https://twitter.com/${text}`} target="_blank" rel="noopener noreferrer">
            @{text}
          </a>}
      />
      <Column
        title="Followers"
        dataIndex="followersCount"
        key="followersCount"
        render={formatNumber}
        sortDirections={['ascend', 'descend']}
        sorter={(a, b) => (a.followersCount - b.followersCount)}
      />
      <Column
        title="Friends"
        dataIndex="friendsCount"
        key="friendsCount"
        render={formatNumber}
        sortDirections={['ascend', 'descend']}
        sorter={(a, b) => (a.friendsCount - b.friendsCount)}
      />
      <Column
        title="Exposure"
        dataIndex="exposure"
        key="exposure"
        sortDirections={['ascend', 'descend']}
        sorter={(a, b) => (a.exposure - b.exposure)}
      />
      <Column
        title="Exposure"
        dataIndex="exposure_perc"
        key="exposure_perc"
        render={text => `${text}%`}
        sortDirections={['ascend', 'descend']}
        sorter={(a, b) => (a.exposure_perc - b.exposure_perc)}
      />
      <Column
        title="Relevance"
        dataIndex="score"
        key="score"
        sortDirections={['ascend', 'descend']}
        sorter={(a, b) => (a.score - b.score)}
      />
      <Column
        title="Action"
        key="action"
        render={(_, record) =>
          <span>
            <a href={`https://twitter.com/${record.userName}`} target="_blank" rel="noopener noreferrer">
              Tweet
            </a>
            <Divider type="vertical" />
            <a href={`https://twitter.com/${record.userName}`} target="_blank" rel="noopener noreferrer">
              Follow
            </a>
          </span>}
      />
    </Table>
  )
}
UsersTable.propTypes = {
  users: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired
}

export default UsersTable
