import React, { useEffect, useState } from 'react'
import UsersTable from './UsersTable'
import users from './users.json'

const Users = () => {
  // const [users, setUsers] = useState([])
  const [errors, setErrors] = useState([])
  const [loading, setLoading] = useState(false)

  // async function fetchData() {
  //   fetch('/api/users').then(res => {
  //     if (!res.ok) throw new Error()
  //     return res.json()
  //   })
  //   .then(({ users }) => {
  //     setUsers(users)
  //     setLoading(false)
  //   })
  //   .catch(e => {
  //     setErrors(x => [...x, e])
  //     setLoading(false)
  //   })
  // }

  // useEffect(() => {
  //   fetchData()
  // }, [])


  return (
    <UsersTable users={users} loading={loading} />
  )
}

export default Users
